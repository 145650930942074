html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  height: 100%;
  padding-bottom: 24px;
}

.h-100 {
  height: 100%!important;
}

.align-items-end {
  align-items: flex-end!important;
}
.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}

.mt-auto, .my-auto {
  margin-top: auto!important;
}
.pb-5, .py-5 {
  padding-bottom: 3rem!important;
}
.w-100 {
  width: 100%!important;
}

.text-white {
  color: #fff!important;
}
.display-1, .display-2 {
  font-weight: 300;
  line-height: 1.2;
}
.display-1 {
  font-size: 6rem;
}

.pt-4, .py-4 {
  padding-top: 1.5rem!important;
}
.justify-content-center {
  justify-content: center!important;
}

.pl-5, .px-5 {
  padding-left: 3rem!important;
}
.pr-5, .px-5 {
  padding-right: 3rem!important;
}
img, svg {
  vertical-align: middle;
}
img {
  border-style: none;
}

.text-center {
  text-align: center!important;
}
.pb-1, .py-1 {
  padding-bottom: .25rem!important;
}
.position-absolute {
  position: absolute!important;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

*, :after, :before {
    box-sizing: border-box;
}